.map-container {
  height: 55vh;
}

@media screen and (min-width: 1280px) and (min-height: 720px) {
  .map-container {
    height: 75vh;
  }
}

@font-face {
  font-family: 'Aharoni';
  src: local('Aharoni'), url("./fonts/Aharoni/Aharoni-bold.ttf") format('truetype');
}

.App {
  font-family: "Aharoni", sans-serif;
}

.Title {
  text-align: center;
  font-size: 5em;
  margin-bottom: 5vh;
}

@media screen and (min-width: 1280px) and (min-height: 720px) {
  .Title{
    font-size: 2em;
    margin-bottom: 1vh;
  }
}

.Title-first {
  color: #118AB2;
}

.Title-second {
  color: #06D6A0;
}

.Map {
  border: solid 2px lightgrey;
  border-radius: 1%;
  margin: 1em;
}

.Notifications {
  position: relative;
}

.Notifications-inner {
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  left: 82.5%;
  top: 0;
  margin: 12px;
  border-radius: 4px;
  font-size: 16px;
}

@media screen and (min-width: 600px) and (min-height: 400px) {
  .Notifications-inner {
    left: 90%;
  }
}

@media screen and (min-width: 1281px) and (min-height: 721px) {
  .Notifications-inner {
    left: 95%;
  }
}

.Notifications-inner--active {
  color: #06D6A0;
}

.Notifications-inner--drawing {
  color: #FFD166;
}

.CarbonSavings {
  margin: 0.5em;
  text-align: center;
  font-family: "Courier New", monospace;
}

.BottomContent {
  position: absolute;
  bottom: 0;
  left: 50%;
  translate: -50%;
  font-family: "Courier New", monospace;
  font-size: 1.2em;
}

.BottomContent p {
  margin-top: 0;
  margin-bottom: 0.5em;
}
